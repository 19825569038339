import { useEffect } from 'react'
import {
  contriesByLanguage,
  BOTONIC_STORAGE_KEY,
  faqBotonicAppId,
  faqBotonicUrl,
  languages,
} from './constants'
import { InMemoryStorage } from './domain/storage/in-memory-storage'
import {getPathData} from './utils/env'
import './assets/styles/app-faq.scss'

declare global {
  interface Window {
    inMemoryStorage: Storage
    botonicOnInit: (app: any) => void | Promise<void>
    botonicOnOpen: (app: any) => void | Promise<void>
    botonicOnClose: (app: any) => void | Promise<void>
    botonicOnMessage: (app: any, message: any) => void | Promise<void>
  }
}

if (!window.inMemoryStorage) {
  window.inMemoryStorage = new InMemoryStorage()
}

const { language, sessionId } = getPathData()
setLanguageInStorage(language)

function FaqBotApp() {
  useEffect(() => {
    loadBotonic('faq-webchatRoot', faqBotonicUrl, {
      hostId: 'faq-webchatRoot',
      appId: faqBotonicAppId,
      onInit: (app: any) => {
        const enableUserInput = language === languages.ENGLISH
        app.updateUser({
          extra_data: {
            language,
            country: contriesByLanguage[language],
            sessionId,
          },
        })
        app.updateWebchatSettings({
          enableUserInput,
        })
        window.botonicOnInit(app)
        app.openCoverComponent()
        app.open()
      },
      onOpen: (app: any) => {
        const enableUserInput = language === languages.ENGLISH
        app.updateUser({
          extra_data: {
            language,
            country: contriesByLanguage[language],
          },
        })
        app.updateWebchatSettings({
          enableUserInput,
        })
        window.botonicOnOpen(app)
      },
      onClose: (app: any) => {
        window.botonicOnClose(app)
        app.open()
      },
    })
  }, [])

  return (
    <div id='faq-main-app-container'>
      <div id='faq-app-container'>
        <div id='faq-webchatRoot' />
      </div>
    </div>
  )
}

function setLanguageInStorage(language: string): void {
  const storage = window.inMemoryStorage.getItem(BOTONIC_STORAGE_KEY) || '{}'
  let storageJson = JSON.parse(storage)
  'session' in storageJson
    ? (storageJson.session.user.extra_data.language = language)
    : (storageJson = { session: { user: { extra_data: { language } } } })

  window.inMemoryStorage.setItem(
    BOTONIC_STORAGE_KEY,
    JSON.stringify(storageJson)
  )
}

function loadBotonic(
  elementId: string,
  botonicUrl: string,
  webchatConfig: Record<string, any>
) {
  const script = document.createElement('script')
  script.src = botonicUrl
  script.onload = () => executeBotonic(elementId, webchatConfig)
  document.head.appendChild(script)
}

function executeBotonic(elementId: string, webchatConfig: Record<string, any>) {
  //@ts-ignore
  Botonic.render(document.getElementById(elementId), webchatConfig)
}

export default FaqBotApp
