import { isProduction } from './utils/env'

export const languages = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  PORTUGUESE: 'pt',
  DUTCH: 'nl',
  SPANISH: 'es',
}

export const countries = {
  GREAT_BRITAIN: 'GB',
  FRANCE: 'FR',
  ITALY: 'IT',
  GERMANY: 'DE',
}

export const contriesByLanguage = {
  [languages.ENGLISH]: countries.GREAT_BRITAIN,
  [languages.FRENCH]: countries.FRANCE,
  [languages.ITALIAN]: countries.ITALY,
  [languages.GERMAN]: countries.GERMANY,
}

export const DEFAULT_LANGUAGE = languages.ENGLISH
export const LANGUAGE_SLUGS: Record<string, string> = {
  dut: languages.DUTCH,
  en: languages.ENGLISH,
  fre: languages.FRENCH,
  ger: languages.GERMAN,
  ita: languages.ITALIAN,
  por: languages.PORTUGUESE,
  spa: languages.SPANISH,
}

export const faqBotonicUrl = isProduction()
  ? 'https://webchat-sdk.hubtype.com/easyjet/faqs/webchat.botonic.js'
  : 'https://faqs-bot-staging-6176ceda-94b6-4555-ad48-eb7bafe87794.netlify.app/webchat.botonic.js'
export const faqBotonicAppId = isProduction()
  ? '03822d1f-0067-4171-ad06-feaafeea1450'
  : 'bc8e7d06-9f5e-4c64-a013-44d08f9f883b'
export const BOT_STORAGE_KEY = 'botonicLocalState'
export const BOTONIC_STORAGE_KEY = 'botonicState'

export const deskProjectIdsByLanguage = isProduction()
  ? {
      [languages.ENGLISH]: 'b3384e16-a967-405b-9e51-17569ee59be4',
      [languages.FRENCH]: 'f16de39e-b895-4ed5-84f0-7ef8373b7b01',
      [languages.GERMAN]: '091bbe3e-f6ac-433d-b418-97e9e313b796',
      [languages.ITALIAN]: '39a4b59d-0883-4abc-b455-9bd8af96336d',
    }
  : {
      [languages.ENGLISH]: 'b7f05ac8-1a22-405f-a1bc-6ad48bda4a40',
      [languages.FRENCH]: '2de0b83f-63c4-4ea0-b5a0-e5a26084c93f',
      [languages.GERMAN]: 'f83a24ee-4ee3-4b99-9282-80e8fff7aeca',
      [languages.ITALIAN]: '79b32aba-6c3a-4a01-9fcb-34487ea8ef8e',
    }
export const ivrQueueIdsByLanguage = isProduction()
  ? {
      [languages.ENGLISH]: '55a385de-f47e-4d93-a610-30c0c8ae0275',
      [languages.GERMAN]: '905be84e-eb09-4df0-9299-868569f5d5bb',
      [languages.FRENCH]: '1b10bf6f-c39c-46a5-a390-38d308f8879b',
      [languages.ITALIAN]: '5aa3a496-41ef-46b3-8e2c-4df966ceedbc',
    }
  : {
      [languages.ENGLISH]: '857a0049-da0f-4a57-8010-63a395703c55',
      [languages.GERMAN]: '3974bc7d-1a8f-4678-bf6b-8638961e27ca',
      [languages.FRENCH]: '984c84cb-163d-415b-b43b-467633089f38',
      [languages.ITALIAN]: '55faf170-891e-4521-87b3-75dc05b9eded',
    }
